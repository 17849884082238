





















































import useSelectItems from "@/use/selectItems";
import { defineComponent, reactive } from "@vue/composition-api";

export default defineComponent({
  components: {
    OParticipantList: () =>
      import("@/components/organisms/event/participant/o-participant-list.vue"),
  },
  props: {
    currency: {
      type: String,
      required: false,
    },
  },

  setup(_, { root }) {
    const state = reactive({
      search: "",
      status: "participant",
      type: undefined,
    });

    const { participantStatusItems } = useSelectItems({ root });

    const participantTypeItems = [
      {
        name: "Dowolny",
        value: "",
      },
      {
        name: "Uczestnik",
        value: "participant",
      },
      {
        name: "Wykładowca",
        value: "lecturer",
      },
    ];

    const hasAccessTo = (permission: string) =>
      root.$store.getters["user/hasAccessTo"](permission);

    return {
      state,
      participantStatusItems,
      participantTypeItems,
      hasAccessTo,
    };
  },
});
